import React, { useState, useEffect, useRef } from 'react';
import './SearchBar.css';
import { applyColors } from '../../../styles/applyColors';
applyColors()

function SearchBar({ onSearch }) {
  const [query, setQuery] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(query);
  };

  const exampleLectures = [
    // "(일반화학2) 용액의 총괄성",
    // "(열역학) 오토 사이클과 디젤 사이클",
    // "(기술시대의 회계) 회계정보는 왜 필요하며, 어떤 역할을 하는가",
    // "(스피치와 토론) 토론내용구성과 전략",
    // "(현대천문학개론) 별의 진화와 종말",
    "하단에 찾고싶은 수업을 검색해보세요!"
  ]

  const exampleQueries = [
    "백색왜성의 탄생에 대해 알려줘",
    "팀프로젝트에서 정책토론 입론을 구성하는 법",
    "기업이 재무제표를 쓰는 이유",
    "라울의 법칙"
  ];

  const handleExampleClick = (example) => {
    setQuery(example);
    onSearch(example);
  };

  const handleLogoClick = () => {
    window.location.reload();
  };

  useEffect(() => {
    const checkScrolling = () => {
      const container = lecturesRef.current;
      if (container) {
        setIsScrolling(container.scrollWidth > container.clientWidth);
      }
    };

    checkScrolling();
    window.addEventListener('resize', checkScrolling);

    return () => window.removeEventListener('resize', checkScrolling);
  }, []);

  const lecturesRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);

  return (
    <div className="main-container">
      <div className="example-lectures-container" ref={lecturesRef}>
        <div className={`example-lectures ${isScrolling ? 'scrolling' : ''}`}>
          {exampleLectures.map((lecture, index) => (
            <span key={index} className="lecture-item">{lecture}</span>
          ))}
        </div>
      </div>
      <div className="search-container">
        <img 
          src="/SF-icon.png" 
          alt="Logo" 
          className="logo" 
          onClick={handleLogoClick}
          style={{ cursor: 'pointer' }}
        />
        <form onSubmit={handleSubmit} className="search-form">
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="질문을 입력하세요"
            className="search-input"
          />
          <button type="submit" className="search-button">검색</button>
        </form>
      </div>
      <div className="example-queries">
        <span className="section-label">검색 예시 :</span>
        {exampleQueries.map((example, index) => (
          <button
            key={index}
            onClick={() => handleExampleClick(example)}
            className="example-button"
          >
            {example}
          </button>
        ))}
      </div>
    </div>
  );
}

export default SearchBar;