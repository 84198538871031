import React, { useEffect, useRef } from 'react';
import './VideoPlayer.css';

function VideoPlayer({ title, url, startTime }) {
  // const videoSrc = `${url}&start=${startTime}&autoplay=1`;
  // const iframeRef = useRef(null); -> url에 시간이 포함되어 있지 않음 / 유튜브는 url에 포함되어 있음
  // ex) https://www.youtube.com/watch?v=sjpGNHPcTLA&t=218s
  const videoRef = useRef(null);

  useEffect(() => {
    // const iframe = iframeRef.current;
    const video = videoRef.current;
    if (video) {
      video.currentTime = startTime;
      // javascript에서 자동재생 제어
      video.play().catch(e => console.error("Auto-play failed:", e));
    }
  }, [url, startTime]);

  return (
    <div className="video-container">
      <video
        ref={videoRef}
        aria-label={title}
        src={url}
        autoplay // 자동재생
        controls
        onLoadedMetadata={() => {
          videoRef.current.currentTime = startTime;
        }}
      >
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default VideoPlayer;