import React, { useState } from 'react';
import axios from 'axios';
import SearchBar from './components/home/header/SearchBar';
import VideoPlayer from './components/home/main/video/VideoPlayer';
import SubtitleSummary from './components/home/main/video/SubtitleSummary';
import ChatInterface from './components/home/main/video/ChatInterface';
import LoadingSpinner from './components/function/LoadingSpinner';
import './App.css';
import { applyColors } from './styles/applyColors';
applyColors()

const SEARCH_API_URL = 'https://9x99tozhjt78.share.zrok.io';

const search_api = axios.create({
  baseURL: SEARCH_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

function App() {
  const [searchResult, setSearchResult] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  console.log(searchResult)

  const handleSearch = async (query) => {
    setError(null);
    setIsLoading(true);
    console.log('Searching for:', query);
    try {
      const response = await search_api.get('/search', { params: { q: query } });
      console.log('API response:', response.data);
      if (response.data && response.data.length > 0) {
        setSearchResult(response.data[0]);
      } else {
        setError('검색 결과가 없습니다.');
      }
    } catch (error) {
      handleApiError(error, '검색 중 오류가 발생했습니다. 다시 시도해 주세요.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleApiError = (error, userMessage) => {
    console.error('API Error:', error);
    setError(userMessage);
    alert(userMessage + ' 콘솔을 확인하세요.');
  };

  return (
    <div className="App">
      <SearchBar onSearch={handleSearch} />
      <div className="divider"></div>
      {error && <p className="error-message">{error}</p>}
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        searchResult ? (
          <div className="content-container">
            <div className='content-video'>
              <div className="video-subtitle">
                <VideoPlayer title={searchResult.title} url={searchResult.url} startTime={searchResult.start_time} />
                <SubtitleSummary title={searchResult.title} subtitle={searchResult.subtitle} summary={searchResult.summary} />
              </div>
            </div>
            <div className='content-chat'>
            </div>
            <ChatInterface subtitle={searchResult.subtitle} />
          </div>
        ) : (
          <div className="default-message">
            <h1>질문은 당신이, 답변은 Sf-agent가 책임집니다</h1>
            <img src="public/main.png" alt="Main Service" />
          </div>
        )
      )}
    </div>
  );
}

export default App;